<template>

    <Section :options="sectionOptions" class="members-list">

        <template v-for="(member, index) in members">

            <div v-if="showLetter(index)" :key="index + '-letter'" :class="headingCssClass">
                <h2>{{ member.profile.lastName.charAt(0) }}</h2>
            </div>

            <div :key="index + '-member'" class="member">
                <div class="grid-inner g-0">
                    <div class="member-image">
                        <img v-if="member.profile.picture" :src="getStaticURLFromPath(member.profile.picture.thumbnail.path)" :alt="member.profile.displayName">
                        <img v-else src="@public/img/authentication/profile-blank.jpg" :alt="member.profile.displayName">
                    </div>
                    <div class="member-desc">
                        <h3>{{ member.profile.displayName }}</h3>
                        <div class="contacts">
                            <span v-if="member.email"><strong>{{ $cms.utils.getComputedLabel(labels.email) }} </strong> {{member.email}}<br/></span>
                        </div>
                    </div>
                </div>
            </div>
        
        </template>
    
    </Section>

</template>

<style>

.members-list .letter {
    color: var(--secondary-color);
}

.members-list .letter.heading-block {
    margin-bottom: 10px;
}

.members-list .letter.heading-block::after {
    margin-top:0px;
}

.members-list .member + .letter {
    margin-top:20px;
}

/* We set the size of the picture */
.members-list .member-image {
    height:100px;
    width: 100px;
    padding-top:5px;
    padding-bottom:5px;
    margin-right: 30px;
    flex: 0 0 auto;
}

/* We set the size of the picture */
.members-list .member-image > a {
    height:100%;
}

/* We set the size of the picture */
.members-list .member-image img {
    width: auto;
    height: 100%;
    margin-left:auto;
    margin-right:auto;
}

/* We set shadow and margin for product list item */
.members-list .member {
    margin: 0 0 10px 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

/* We ensure that picture is verticaly aligned with content */
.members-list .grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
    .members-list .member-image {
        width: 100%;
        margin-bottom: 20px;
    }
    .members-list .grid-inner {
      flex-wrap: wrap;
    } 
}

/* We display the content next to the picture */
.members-list .member-desc {
    padding-top:5px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
}

@media (max-width: 767.98px) {
    .members-list .member-desc {
      padding-left:10px;
    } 
}

.members-list .member-desc h3 {
    margin-bottom: 10px;
}



</style>

<script lang="ts">
import { defineComponent, PropType, watch, computed, onMounted, onBeforeUnmount, ref, Ref } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import * as api from '@fwk-client/utils/api';
import { Group } from '../team/types';
import { GroupLabels, GroupOptions } from '../team/Group.vue';
import { CmsEnum, CmsLabel } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface MembersOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions,
    /** @cmsType CmsEnum */
    headingStyle?:CmsEnum<"heading-block"|"fancy-title">,
}

/** @cmsLabels */
export interface MembersLabels {
    /** @cmsType CmsLabel */
    email?:CmsLabel;
}

/** @cmsSlots */
export interface MembersSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<MembersOptions>,
        labels: {
          type: Object as PropType<MembersLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<MembersSlots>
    },
    components: {
        Section
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const members:Ref<any[]> = ref([]);

        const membersCompareFunction = (member1:any, member2:any) => {
            return member1.profile.lastName.localeCompare(member2.profile.lastName);
        }

        var options:api.ApiVueOptions =  {
            app
        }
        api.getAPI('/api/auth/members/list', options).then((response:any) => {
            if(response.users) {
                members.value = response.users.sort(membersCompareFunction);
                
            }
            else {
                members.value = [];
            }
        });
        
        const computedOptions:MembersOptions = {
            sectionOptions : {
                
            },
            headingStyle : "heading-block",
            ...props.options
        };

        const headingCssClass = computedOptions.headingStyle == "heading-block" ? "letter heading-block center" : "letter fancy-title title-border title-center";

        const computedLabels:MembersLabels = {
            email : {
                fr : "E-Mail:"
            },
            ...props.labels
        }

        const showLetter = (index:number) => {
            return index == 0 || members.value[index].profile.lastName.charAt(0) != members.value[index-1].profile.lastName.charAt(0)
        }
        

        return {
            showLetter:showLetter,
            headingCssClass : headingCssClass,
            members:members,
            sectionOptions:computedOptions.sectionOptions,
            labels:computedLabels
        }

    }
})
</script>